

import whatsapp from "../../assests/images/whatsapp.png";
import youtube from "../../assests/images/youtube.png";
import insta from "../../assests/images/insta.png";
import facebook from "../../assests/images/facebook.svg";
import locate from "../../assests/images/locate.png";
import phone from "../../assests/images/phone.png";
import mail from "../../assests/images/mail.png";
import Linkindin from "../../assests/images/linkedin.svg"
import whatuplogo from "../../assests/images/logos_whatsapp-icon.svg"
import "./index.css"
import cleanworldlog2 from "../../assests/images/cleanworld-logo0.png"
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
const FooterPage=()=>{
    const { t } = useTranslation();
    return(
    <>
<footer class="footer-section">
  <div class="container">
      <div class="footer-content footer-cta pt-5 pb-5">
          <div class="row foot-center">
              <div class="col-xl-5 col-lg-5 mb-50">
                  <div class="footer-widget">
                      <div class="footer-logo">
                          <a href="index.html"><img src={cleanworldlog2} class="img-fluid" alt="logo"/></a>
                      </div>
                      <div class="footer-text" style={{textAlign:"justify"}}>
                          <p>{t('footer_comment')}</p>
                      </div>
                      <div class="footer-social-icon">
                          <span>{t('follow_us')}</span>
                          {/* <a href="#"><img src={whatsapp}/></a> */}
                          <a href="https://www.linkedin.com/company/cleanworldme/" target="_blank"><img src={Linkindin}/></a>
                          <a href="https://www.instagram.com/ccms_me?utm_source=qr&igsh=YXd1OXdpZ3g2YjBy" target="_blank"><img src={insta}/></a>
                          <a href="https://www.facebook.com/profile.php?id=61554485678061" target="_blank"><img src={facebook}/></a>
                      </div>
                  </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-5 mb-30">
                  <div class="footer-widget">
                      <div class="footer-widget-heading">
                          <h3>{t('inner_page')}</h3>
                      </div>
                      <ul>
                         
          <li > <NavLink to="/">{t('menu_bar_Home')}</NavLink></li>
          <li><NavLink to="/about" >{t('menu_bar_About')}</NavLink></li>
          <li><NavLink to="/features" >{t('menu_bar_Features')}</NavLink></li>
          <li><NavLink to="/testimonials" >{t('menu_bar_Testimonials')}</NavLink></li>
          <li><NavLink to="/contact-us" >{t('menu_bar_Contact')}</NavLink></li>
                      </ul>
                  </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 mb-50">
                  <div class="footer-widget">
                      <div class="footer-widget-heading">
                          <h3>{t('menu_bar_Contact')}</h3>
                      </div>
                      <div class="footer-text mb-25">
                          <div class="foot-ic"><div class="foot-ic1"><img src={locate}/></div><div class="foot-add" style={{textAlign:"justify"}}>{t('footer_address_cmpny')}</div></div>
                          <div class="foot-ic"><div class="foot-ic1"><img src={phone}/></div><div class="foot-add"><a href="tel:+971 52 442 4067">+971 52 442 4067</a></div></div>
                          <div class="foot-ic"><div class="foot-ic1"><img src={mail}/></div><div class="foot-add"><a href="mailto:mail@accruon.ae">mail@accruon.ae</a></div></div>
                      </div>
                      
                  </div>
              </div>
          </div>
      </div>
  </div>
  

  <div class="copyright-area footer-cta">
      <div class="container">
          <div class="row foot-last">
              <div class="col-xl-6 col-lg-6 text-center text-lg-left">
                  <div class="copyright-text">
                      <p>Copyright © 2023 Cleaning Companies Management System</p>
                  </div>
              </div>
    
          </div>
      </div>
  </div>
</footer>


    </>)
}
export default FooterPage;