
import headerImg from "../../assests/images/about-banner.png";
import ccmsAbout from "../../assests/images/ccms-about.png";
import ccmsbanner from "../../assests/images/ccmsaboutbg.jpg";

import flowcharticon from "../../assests/images/flowchart/icons-s.png";
import phoneicon from "../../assests/images/flowchart/phone.png";
import enquiry from "../../assests/images/flowchart/enquiry.png";
import message from "../../assests/images/flowchart/msg.png";
import pencil from "../../assests/images/flowchart/pencil.png"
import file from "../../assests/images/flowchart/file.png";
import supervisor from "../../assests/images/flowchart/supervisor.png";
import quest from "../../assests/images/flowchart/quest.png";
import inspection from "../../assests/images/flowchart/inspection-report.png"
import workorder from "../../assests/images/flowchart/work-order.png";
import tick from "../../assests/images/flowchart/tick.png"
import quote from "../../assests/images/flowchart/quote.png";
import approval from "../../assests/images/flowchart/approval.png";
import job from "../../assests/images/flowchart/job.png";
import selection from "../../assests/images/flowchart/selection.png";
import stock from "../../assests/images/flowchart/stock.png";
import payment from "../../assests/images/flowchart/payment.png";
import collection from "../../assests/images/flowchart/collection.png";
import tick1 from "../../assests/images/flowchart/tick1.png";
import lead from "../../assests/images/flowchart/lead.png";
import complete from "../../assests/images/flowchart/complete.png";
import invoice from "../../assests/images/flowchart/invoice.png";
import lead1 from "../../assests/images/flowchart/lead1.png";
import feedback from "../../assests/images/flowchart/feedback.png"
import NavBarPage from "../components/navbar";
import FooterPage from "../components/footer";
import twentyfourseven from "../../assests/images/features/7.png"
import followup from "../../assests/images/features/Follow up .png"
import ensurequote from "../../assests/images/features/quotes.png"
import whatsup from "../../assests/images/features/Whats app.png"
import inventerymanagement from "../../assests/images/features/Inventory management.png"
import emplymanagment from "../../assests/images/features/Employee management.png"
import instinvoice from "../../assests/images/features/Instant invoice generation.png"
import collectinvoice from "../../assests/images/features/Ensure the collection of all invoices.png"
import datamanagement from "../../assests/images/features/Customer data management.png"
import realtimedata from "../../assests/images/features/Access to real-time data.png"
import profitability from "../../assests/images/features/profitability.png"
import managedjob from "../../assests/images/features/Manage contract jobs.png"
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
const CleanWorldFeaturePage = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
  return (
    <>
      <NavBarPage />
      <section
        class="breadcrumb-area1"
        style={{ backgroundImage: `url(${ccmsbanner})` }}
      >
        {/* <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="inner-content clearfix">
              <div class="title text-center">
                <h1>{t('feature_page_content_heading')}</h1>

              </div>
            </div>
          </div>
        </div>
      </div> */}
        <div class="container">
          <div class="strip-main">
            <div>
              <h1>{t("feature_page_content_heading")}</h1>
            </div>
            <div class="strip-banner1">
              <img src={ccmsAbout} />
            </div>
          </div>
        </div>
      </section>
      <section class="main-section">
        <div class="container">
          <div class="row justify-content-center content-top">
            <div class="col-lg-7 col-md-12 top-img top-base">
              <div class="features-title">
                <h2 class=""> {t("feature_page_content_heading1")}</h2>
                <p>
                  <ul>
                    <li> {t("feature_page_heading1_content1")}</li>
                  </ul>
                </p>
              </div>
            </div>
            <div class="col-lg-5 col-md-12">
              <img src={twentyfourseven} class="feature-img" />
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-5 col-md-12 img-left">
              <img src={followup} class="feature-img" />
            </div>
            <div class="col-lg-7 col-md-12 top-img top-base">
              <div class="features-title2">
                <h2 class=""> {t("feature_page_content_heading2")}</h2>
                <ul>
                  <li>{t("feature_page_heading2_content1")}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-7 col-md-12 top-img top-base">
              <div class="features-title">
                <h2 class=""> {t("feature_page_content_heading3")}</h2>
                <ul>
                  <li>{t("feature_page_heading3_content1")}</li>
                  <li>{t("feature_page_heading3_content2")}</li>
                </ul>
              </div>
            </div>
            <div class="col-lg-5 col-md-12">
              <img src={ensurequote} class="feature-img" />
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-5 col-md-12 img-left">
              <img src={whatsup} class="feature-img" />
            </div>
            <div class="col-lg-7 col-md-12 top-img top-base">
              <div class="features-title2">
                <h2 class=""> {t("feature_page_content_heading4")} </h2>
                <ul>
                  <li>{t("feature_page_heading4_content1")}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-7 col-md-12 top-img top-base">
              <div class="features-title">
                <h2 class=""> {t("feature_page_content_heading5")}</h2>
                <ul>
                  <li>{t("feature_page_content_heading5_content1")}</li>
                  <li>{t("feature_page_content_heading5_content2")}</li>
                </ul>
              </div>
            </div>
            <div class="col-lg-5 col-md-12">
              <img src={inventerymanagement} class="feature-img" />
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-5 col-md-12 img-left">
              <img src={emplymanagment} class="feature-img" />
            </div>
            <div class="col-lg-7 col-md-12 top-img top-base">
              <div class="features-title2">
                <h2 class=""> {t("feature_page_content_heading6")} </h2>
                <ul>
                  <li> {t("feature_page_content_heading6_content1")}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-7 col-md-12 top-img top-base">
              <div class="features-title">
                <h2 class=""> {t("feature_page_content_heading7")}</h2>
                <ul>
                  <li>{t("feature_page_content_heading7_content1")}</li>
                </ul>
              </div>
            </div>
            <div class="col-lg-5 col-md-12">
              <img src={instinvoice} class="feature-img" />
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-5 col-md-12 img-left">
              <img src={collectinvoice} class="feature-img" />
            </div>
            <div class="col-lg-7 col-md-12 top-img top-base">
              <div class="features-title2">
                <h2 class=""> {t("feature_page_content_heading8")} </h2>
                <ul>
                  <li> {t("feature_page_content_heading8_content1")}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-7 col-md-12 top-img top-base">
              <div class="features-title">
                <h2 class=""> {t("feature_page_content_heading9")}</h2>
                <ul>
                  <li>{t("feature_page_content_heading9_content1")}</li>
                </ul>
              </div>
            </div>
            <div class="col-lg-5 col-md-12">
              <img src={datamanagement} class="feature-img" />
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-5 col-md-12 img-left">
              <img src={realtimedata} class="feature-img" />
            </div>
            <div class="col-lg-7 col-md-12 top-img top-base">
              <div class="features-title2">
                <h2 class=""> {t("feature_page_content_heading10")}</h2>
                <ul>
                  <li>{t("feature_page_content_heading10_content1")} </li>
                  <li>{t("feature_page_content_heading10_content2")}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-7 col-md-12 top-img top-base">
              <div class="features-title">
                <h2 class=""> {t("feature_page_content_heading11")}</h2>
                <ul>
                  <li>{t("feature_page_content_heading11_content1")}</li>
                </ul>
              </div>
            </div>
            <div class="col-lg-5 col-md-12">
              <img src={profitability} class="feature-img" />
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-5 col-md-12 img-left">
              <img src={managedjob} class="feature-img" />
            </div>
            <div class="col-lg-7 col-md-12 top-img top-base">
              <div class="features-title2">
                <h2 class=""> {t("feature_page_content_heading12")}</h2>
                <ul>
                  <li>{t("feature_page_content_heading12_content1")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterPage />
    </>
  );
}
export default CleanWorldFeaturePage;