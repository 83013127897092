import FooterPage from "../components/footer";
import NavBarPage from "../components/navbar";
import { useForm, Controller } from "react-hook-form";
import { Button, notification, Space } from 'antd';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useState } from "react";
import "./index.css"
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import axios from "axios";
import { useTranslation } from "react-i18next";
const CleantalContactusPage = () => {
    const { t } = useTranslation();
    const { control, register, handleSubmit } = useForm();
    const [successMessage, setSuccessMessage] = useState("");
    const [api, contextHolder] = notification.useNotification();
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    const openNotificationWithIcon = (type) => {
        api[type]({
          message: 'Success',
          description:
            'Thank you for choosing us',
        });
      };
    const handleonSubmit = (data) => {
        console.log(data)
        const postData = {
            fullName: data.name,
            email: data.email,
            phone: data.phnnumber,
            description: data.message,
            location: data.city
        }
     
        axios
            .post("https://api.accruonconsultants.com/accruonConsultantApi/cleanWorldContactMail", postData )
            .then((response) => {
                if (response.data.message == "Success") {
                    openNotificationWithIcon('success')
                    console.log(response)

                }
            })

    }
    const onInvalid = (errors) => console.error(errors)
    return (
      <>
        <NavBarPage />
        {contextHolder}

        <section class="contact" id="contact">
          <div class="container">
            <div class="heading text-center">
              <h3 class="about-h">{t("get_in_touch_heading")}</h3>
              <p> {t("get_in_touch")}</p>
            </div>
            <div class="row">
              <div class="col-md-4 address-side">
                <div class="content">
                  <div class="info">
                    <h4 class="d-inline-block">
                      {t("address")}
                      <br />
                      <span>{t("footer_address_cmpny")}</span>
                    </h4>
                  </div>
                  {/* <!-- Info-2 --> */}
                  <div class="info">
                    <h4 class="d-inline-block">
                      {t("email_us")}
                      <br />
                      <span>mail@accruon.ae</span>
                    </h4>
                  </div>
                  {/* <!-- Info-3 --> */}
                  <div class="info">
                    <h4 class="d-inline-block">
                      {t("call_us")}
                      <br />
                      <span>+971 52 442 4067</span>
                    </h4>
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                <form
                  class="contact-form"
                  onSubmit={handleSubmit(handleonSubmit, onInvalid)}
                >
                  <div class="row">
                    <div class="col-sm-12">
                      <label>Name</label>
                      <input
                        type="text"
                        class="form-control"
                        autoComplete="off"
                        placeholder=""
                        {...register("name")}
                        required
                      />
                    </div>
                    <div class="col-sm-12">
                      <label>Email</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        {...register("email")}
                        required
                      />
                    </div>
                    <div class="col-sm-6">
                      <label>Phone</label>
                      <Controller
                        autoComplete="off"
                        control={control}
                        name="phnnumber"
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                          <PhoneInput
                            class="form-control"
                            {...field}
                            inputExtraProps={{
                              ref,
                              required: true,
                              autoFocus: true,
                            }}
                            country={"ae"}
                            autoComplete="off"
                            enableSearch={true}
                          />
                        )}
                      />
                    </div>

                    <div class="col-sm-6">
                      <label>City</label>
                      <input
                        type="text"
                        class="form-control"
                        autoComplete="off"
                        placeholder=""
                        {...register("city")}
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Company Details</label>
                    <textarea
                      class="form-control"
                      rows="5"
                      id="comment"
                      autoComplete="off"
                      placeholder="Message"
                      {...register("message")}
                    ></textarea>
                  </div>
                  {successMessage && (
                    <div className="success-msg"> {successMessage} </div>
                  )}
                  <button class="btn btn-block" type="submit">
                    Send
                  </button>
                </form>

                {/* <form class="contact-form">
                                <div class="row">

                                    <div class="col-sm-12">
                                        <label>Name</label>
                                        <input type="text" class="form-control" placeholder=""/>
                                    </div>
                                    <div class="col-sm-12">
                                    <Controller
                                    control={control}
                                    name="phnnumber"
                                    rules={{ required: true }}
                                    render={({ field: { ref, ...field } }) => (
                                        <PhoneInput
                                            {...field}
                                            inputExtraProps={{
                                                ref,
                                                required: true,
                                                autoFocus: true
                                            }}
                                            class="form-control"
                                            country={"in"}
                                            autoComplete="off"
                                            enableSearch={true}
                                        />
                                    )}
                                /> 
                                </div>
                                    <div class="col-sm-12">
                                        <label>Phone</label>
                                        <input type="text" class="form-control" placeholder=""/>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Location</label>
                                        <input type="text" class="form-control" placeholder=""/>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>City</label>
                                        <input type="text" class="form-control" placeholder=""/>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Message(Optional)</label>
                                    <textarea class="form-control" rows="5" id="comment" placeholder="Message"></textarea>
                                </div>
                                <button class="btn btn-block" type="submit">Send</button>
                            </form> */}
              </div>
            </div>
          </div>
        </section>
        <FooterPage />
      </>
    );
}
export default CleantalContactusPage;