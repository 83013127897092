

import ourfeatures from "../../../assests/images/webp/our-features_11zon.webp"
import aboutclean from "../../../assests/images/webp/about-clean_11zon.webp"
import featureback from "../../../assests/images/about-bg.png"
import { useTranslation} from "react-i18next";
const AboutFeaturePage = () => {
  const { t } = useTranslation();
  return (
    <>
      <section class="main-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-12 top-img">
              <div class="title-wrapper">
                <h2 class="left h1-b"> <span>{t('about_name')}</span></h2>
                
                <p class="about-p1">{t('about_para1')}</p>
                {/* <p>{t('about_para2')}</p> */}
              </div>
            </div>
            <div class="col-lg-5 col-md-12 md-pb1">
              <img src={aboutclean} class="mob-img" />
            </div>
          </div>
        </div>
      </section >
      <section class="main-section main-bg" style={{ backgroundImage: `url(${featureback})` }}>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-12 md-pb title-wrapper">
              <h2 class="left h1-b">{t('heading_our')} <span>{t('menu_bar_Features')}</span></h2>
              <img src={ourfeatures} class="mob-img" />
            </div>
            <div class="col-lg-7 col-md-12 top-img">
              <div class="title-wrapper">
                <p class="about-p1">
                  <div class="uvp-list">
                    <ul>
                      <li>{t('home_content_feature1')}</li>
                      <li>{t('home_content_feature2')}</li>
                      <li>{t('home_content_feature3')}</li>
                      <li>{t('home_content_feature4')}</li>
                      <li>{t('home_content_feature5')}</li>
                      <li>{t('home_content_feature6')}</li>
                      <li>{t('home_content_feature7')}</li>
                      <li>{t('home_content_feature8')}</li>
                      <li>{t('home_content_feature9')}</li>
                      <li>{t('home_content_feature10')}</li> 
                    </ul>
                  </div>

                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default AboutFeaturePage;