import NavBarPage from "../navbar";
import FooterPage from "../footer";
// import about from "../../../assests/images/about-banner.png";
import about from "../../../assests/images/ccmsaboutbg.jpg"
import ccmsAbout from "../../../assests/images/ccms-about.png"
import aboutMain from "../../../assests/images/about-main.png";
import vidoe1 from "../../home/vidoegallery/video1.mp4";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import flowcharticon from "../../../assests/images/flowchart/icons-s.png";

import phoneicon from "../../../assests/images/flowchart/phone.png";
import enquiry from "../../../assests/images/flowchart/enquiry.png";
import message from "../../../assests/images/flowchart/msg.png";
import pencil from "../../../assests/images/flowchart/pencil.png";
import file from "../../../assests/images/flowchart/file.png";
import supervisor from "../../../assests/images/flowchart/supervisor.png";
import quest from "../../../assests/images/flowchart/quest.png";
import inspection from "../../../assests/images/flowchart/inspection-report.png";
import workorder from "../../../assests/images/flowchart/work-order.png";
import tick from "../../../assests/images/flowchart/tick.png";
import quote from "../../../assests/images/flowchart/quote.png";
import approval from "../../../assests/images/flowchart/approval.png";
import job from "../../../assests/images/flowchart/job.png";
import selection from "../../../assests/images/flowchart/selection.png";
import stock from "../../../assests/images/flowchart/stock.png";
import payment from "../../../assests/images/flowchart/payment.png";
import collection from "../../../assests/images/flowchart/collection.png";
import tick1 from "../../../assests/images/flowchart/tick1.png";
import lead from "../../../assests/images/flowchart/lead.png";
import complete from "../../../assests/images/flowchart/complete.png";
import invoice from "../../../assests/images/flowchart/invoice.png";
import lead1 from "../../../assests/images/flowchart/lead1.png";
import feedback from "../../../assests/images/flowchart/feedback.png";
import { useTranslation } from "react-i18next";
const CleanWorldAboutPage = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <NavBarPage />
      <section
        class="breadcrumb-area1"
        style={{ backgroundImage: `url(${about})` }}
      >
        {/* <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="inner-content clearfix">
                <div class="title text-center">
                  <h1> {t("about_name")} </h1>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div class="container">
          <div class="strip-main">
            <div>
              <h1>{t("about_name")}</h1>
            </div>
            <div class="strip-banner1">
              <img src={ccmsAbout} />
            </div>
          </div>
        </div>
      </section>
      {/* {t("about_name")} */}
      {/* <section class="breadcrumb-area1" style="background-image: url(img/ccms-banner.jpg);">
          <div class="container">
                <div class="strip-main">
              <div>
                <h1>About Cleaning World</h1>
              </div>
               <div class="strip-banner1">
                <img src="img/ccms-about.png"/>
              </div>
                </div>
            </div>
            </section> */}
      <section class="main-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-12 top-img">
              <div class="title-about" style={{ textAlign: "justify" }}>
                <h2 class=""> {t("about_page_heading")}</h2>
                <h3 class="head-m">({t("website_name")})</h3>
                <h4>{t("about_page_heading1")}</h4>
                <p>{t("about_page_para1")}</p>
                <p> {t("about_page_para2")}</p>
                <p>{t("about_page_para3")}</p>
              </div>
            </div>
            <div class="col-lg-5 col-md-12 md-pb1">
              <img src={aboutMain} class="mob-img" />
            </div>
          </div>
        </div>
      </section>

      <div class="responsive-content container d-ar1">
        <div class="title-about">
          <h2 style={{ textAlign: "center" }}>
            {t("about_flow_chart_heading")}
          </h2>
        </div>
        <figure class="org-chart cf">
          <div class="board ">
            <ul class="columnThree">
              <li class="first-li">
                <span className="m-one">
                  <img src={flowcharticon} class="img-ic" />
                  <br />
                  {t("about_flow_chart_content1")}
                </span>
              </li>
              <li class="first-li md2" id="f-lis">
                <span className="m-one">
                  <img src={phoneicon} class="img-log" />
                  <br />
                  {t("about_flow_chart_content2")}
                </span>
              </li>
            </ul>
            <ul class="columnOne">
              <li class="enq-list">
                <span className="not-one m-one">
                  <img src={enquiry} class="img-log" />
                  <br /> {t("about_flow_chart_content3")}
                </span>
              </li>
            </ul>
            <ul class="columnOne1 colm1">
              <li class="enq-sub">
                <span class="lvl-b m-one">
                  <img src={message} />
                  {t("about_flow_chart_content29")}
                </span>
              </li>
              <li class="enq-list">
                <span className="m-one">
                  <img src={file} class="img-log" />
                  <br /> {t("about_flow_chart_content4")}
                </span>
              </li>
            </ul>
            <ul class="columnOne1">
              <li class="enq-list1 manage1">
                <span className="m-one c-p1">
                  <img src={pencil} class="img-log" />
                  <br />
                  {t("about_flow_chart_content5")}
                </span>
              </li>
            </ul>
            <ul class="columnOne1 rgt11">
              <li class="enq-list">
                <span class="lvl-b m-one">
                  <img src={message} />
                  {t("about_flow_chart_content28")}
                </span>
              </li>
            </ul>
            <ul class="columnThree40">
              <li class="rgt2">
                <span className="not-one sec-p1 m-one">
                  <img src={supervisor} class="img-log" />
                  {t("about_flow_chart_content27")}
                </span>
                <div class="yes">Yes</div>
                <div class="no">No</div>
              </li>
              <li class="nw1">
                <span className="m-one">
                  {t("about_flow_chart_content6")}

                  <img src={quest} class="img-log" />
                </span>
              </li>
            </ul>
            <ul class="columnThree40">
              <li class="rgt2">
                <span className="not-one sec-p1 m-one">
                  <img src={inspection} class="img-log" />
                  {t("about_flow_chart_content26")}
                </span>
              </li>
              <li class="nw1">
                <span className="m-one">
                  <img src={workorder} class="img-log" />
                  {t("about_flow_chart_content7")}
                </span>
              </li>
            </ul>

            <ul class="columnOne1">
              <li class="enq-list">
                <span className="m-one">
                  <img src={tick} class="img-log" />
                  {t("about_flow_chart_content8")}
                </span>
              </li>
            </ul>

            <ul class="columnThree30">
              <li>
                <span class="lvl-b m-one">
                  <img src={message} class="img-log" />
                  {t("about_flow_chart_content30")}
                </span>
              </li>
              <li>
                {" "}
                <span className="m-one">
                  <img src={quote} class="img-log" />
                  {t("about_flow_chart_content9")}
                </span>
              </li>
            </ul>

            <ul class="columnOne1">
              <li class="enq-list">
                <span className="m-one">
                  <img src={approval} class="img-log" />
                  {t("about_flow_chart_content10")}
                </span>
              </li>
            </ul>
            <ul class="columnOne1">
              <li class="enq-list manage2">
                <span className="m-one c-p1">
                  <img src={job} class="img-log" />
                  {t("about_flow_chart_content11")}
                </span>
              </li>
            </ul>
            <ul class="columnOne1">
              <li class="enq-list">
                <span className="m-one c-p1">
                  <img src={selection} class="img-log" />
                  {t("about_flow_chart_content12")}
                </span>
              </li>
            </ul>
            <ul class="columnOne1">
              <li class="enq-list">
                <span className="m-one c-p1">
                  <img src={stock} class="img-log" />
                  {t("about_flow_chart_content13")}
                </span>
              </li>
            </ul>
            <ul class="columnOne1">
              <li class="enq-list">
                <span className="m-one c-p1">
                  <img src={payment} class="img-log" />
                  {t("about_flow_chart_content14")}
                </span>
              </li>
            </ul>
            <ul class="columnOne1">
              <li class="enq-list">
                <span className="m-one c-p1">
                  <img src={collection} class="img-log" />
                  {t("about_flow_chart_content15")}
                </span>
              </li>
            </ul>
            <ul class="columnThree30">
              <li>
                <span class="lvl-b m-one">
                  <img src={message} class="img-log" />
                  {t("about_flow_chart_content24")}
                </span>
              </li>
              <li>
                <span className="m-one">
                  <img src={tick1} class="img-log" />
                  {t("about_flow_chart_content16")}
                </span>
              </li>
            </ul>
            <ul class="columnThree3">
              <li class="tn1">
                <span class="lvl-b m-one">
                  <img src={message} class="img-log" />
                  {t("about_flow_chart_content23")}
                </span>
              </li>
              <li>
                <span className="m-one">
                  <img src={lead} class="img-log" />
                  {t("about_flow_chart_content17")}
                </span>
              </li>
              <li class="tn1">
                <span class="lvl-b m-one">
                  <img src={message} class="img-log" />
                  {t("about_flow_chart_content25")}
                </span>
              </li>
            </ul>
            <ul class="columnOne1">
              <li class="enq-list">
                <span className="m-one">
                  <img src={complete} class="img-log" />
                  {t("about_flow_chart_content18")}
                </span>
              </li>
            </ul>

            <ul class="columnThree30">
              <li class="tn1">
                <span class="lvl-b m-one">
                  <img src={message} class="img-log" />
                  {t("about_flow_chart_content22")}
                </span>
              </li>
              <li>
                <span className="m-one">
                  <img src={invoice} class="img-log" />
                  {t("about_flow_chart_content19")}
                </span>
              </li>
            </ul>

            <ul class="columnOne1">
              <li class="enq-list">
                <span className="m-one">
                  <img src={lead1} class="img-log" />
                  {t("about_flow_chart_content20")}
                </span>
              </li>
            </ul>

            <ul class="columnOne1 demo1">
              <li class="enq-list med-on manage2">
                <span className="m-one">
                  <img src={feedback} class="img-log" />
                  {t("about_flow_chart_content21")}
                </span>
              </li>
            </ul>
          </div>
        </figure>
      </div>
      <section class="main-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
              <div class="box-z">
                <div class="circle-1">{t("about_advantage_content1")}</div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
              <div class="box-z">
                <div class="circle-2">{t("about_advantage_content2")}</div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
              <div class="box-z">
                <div class="circle-3">{t("about_advantage_content3")} </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
              <div class="box-z">
                <div class="circle-4">{t("about_advantage_content4")}</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="main-section">
        {/* <div class="container">
                    <div class="row gallery-b">


                        <div class="col-md-4 video-container-b">
                            <video autoPlay={true} muted loop>
                                <source src={vidoe1} type="video/mp4" />
                            </video>

                        </div>
                        <div class="col-md-4 video-container-b">
                            <video autoPlay={true} muted loop>
                                <source src={vidoe1} type="video/mp4" />
                            </video>

                        </div>
                        <div class="col-md-4 video-container-b">
                            <video autoPlay={true} muted loop>
                                <source src={vidoe1} type="video/mp4" />
                            </video>
                        </div>
                        <div class="col-md-4 video-container-b">
                            <video autoPlay={true} muted loop>
                                <source src={vidoe1} type="video/mp4" />
                            </video>
                        </div>
                        <div class="col-md-4 video-container-b">
                            <video autoPlay={true} muted loop>
                                <source src={vidoe1} type="video/mp4" />
                            </video>
                        </div>
                        <div class="col-md-4 video-container-b">
                            <video autoPlay={true} muted loop>
                                <source src={vidoe1} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div> */}
      </section>
      <FooterPage />
    </>
  );
};
export default CleanWorldAboutPage;
