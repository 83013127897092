import logo from './logo.svg';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';

import CleanWorldHomePAge from './routes/home';
import CleanWorldAboutPage from './routes/components/about';
import CleanWorldFeaturePage from './routes/features';
import TestimoialPageClean from './routes/testimonials';
import CleantalContactusPage from './routes/contact-us';
import CleanWorldPrivacyPolicy from './routes/privacypolicy';
function App() {
  return (
    <>
 
 


 <Routes>
        <Route path="/" element={<CleanWorldHomePAge />} />
        <Route path="/about" element={<CleanWorldAboutPage />} />
        <Route path="/features" element={<CleanWorldFeaturePage/>}/>
         <Route path="/testimonials" element={<TestimoialPageClean/>}/>
        <Route path="/contact-us" element={<CleantalContactusPage/>}/>
        <Route path="/privacy-policy" element={<CleanWorldPrivacyPolicy/>}/>
      
        </Routes> 
    </>
  );
}

export default App;
