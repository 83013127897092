
import headerImg from "../../assests/images/about-banner.png";
import ccmsbanner from "../../assests/images/ccmsaboutbg.jpg";
import ccmsAbout from "../../assests/images/ccms-about.png";
import NavBarPage from "../components/navbar";
import FooterPage from "../components/footer";
import { useLocation } from 'react-router-dom';
import React,{ useEffect,useState } from 'react';
import { useTranslation} from "react-i18next";
const TestimoialPageClean = () => {
  const { t } = useTranslation();
  const [readMore,setReadMore]=useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  const text=<><p class="about-p1" style={{ textAlign: "justify" }}>
  {t('testimonials_home_para2')} </p>

  <p class="about-p1" style={{ textAlign: "justify" }}>
  {t('testimonials_home_para3')}</p></>
  const linkName=readMore?<span>Show less</span>:<span>Show more</span>
  return (
    <>
      <NavBarPage />
      <section
        class="breadcrumb-area1"
        style={{ backgroundImage: `url(${ccmsbanner})` }}
      >
        {/* <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="inner-content clearfix">
              <div class="title text-center">
                <h1>{t('menu_bar_Testimonials')}</h1>

              </div>
            </div>
          </div>
        </div>
      </div> */}
        <div class="container">
          <div class="strip-main">
            <div>
              <h1>{t("menu_bar_Testimonials")}</h1>
            </div>
            <div class="strip-banner1">
              <img src={ccmsAbout} />
            </div>
          </div>
        </div>
      </section>
      <div class="container">
        <section class="main-section">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-12">
              <div class="title-wrapper">
                <iframe
                  class="video-one"
                  height
                  src="https://www.youtube.com/embed/BuG92IiMCPM?autoplay=1&amp;mute=1&amp;rel=0"
                ></iframe>
              </div>
            </div>
            <div class="col-lg-7 col-md-12 md-pb">
              <h5 class="left h1-b"> {t("client_name")}</h5>
              <h6 class="left h1-b">{t("client_position")}</h6>
              <div class="client-rating">
                <i class="fa fa-star" aria-hidden="true"></i>
                <i class="fa fa-star" aria-hidden="true"></i>
                <i class="fa fa-star" aria-hidden="true"></i>
                <i class="fa fa-star" aria-hidden="true"></i>
                <i class="fa fa-star" aria-hidden="true"></i>
              </div>
              {/* {showMore ? text : `${text.substring(0, 250)}`} */}
              <p class="about-p1" style={{ textAlign: "justify" }}>
                {t("testimonials_home_para1")}
              </p>
            </div>
            <div class="col-lg-12 col-md-12 md-pb">{readMore && text}</div>
            <button
              class="test-btn"
              onClick={() => {
                setReadMore(!readMore);
              }}
            >
              {linkName}
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </button>
          </div>
        </section>
      </div>

      <FooterPage />
    </>
  );
}
export default TestimoialPageClean;