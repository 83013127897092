

import { useState } from "react";
import "../../assests/css/style.css";
// import "bootstrap/dist/js/bootsrap.js"
// import "bootstrap/dist/css/bootsrap.min.css"
// import "flag-icon-css/css/flag-icon.min.css"
import { NavLink } from "react-router-dom";
import cleanworldlog from "../../assests/images/cleanworld-logo.svg"
import i18next from "i18next";
import { useTranslation } from "react-i18next";
const NavBarPage = () => {
  const { t } = useTranslation();
  const language = [
    {
      code: 'en',
      name: 'english',
      country_code: 'gb'
    },
    {
      code: 'ar',
      name: 'عربي',
      country_code: 'sa'
    }
  ]

  return (
    <>

      <div class="nav-one">
        <nav class="nav container">

          <input type="checkbox" id="nav__checkbox" class="nav__checkbox" />
          <label for="nav__checkbox" class="nav__toggle">
            <img src="https://img.icons8.com/material-outlined/30/000000/menu--v1.png" class="hamburger" />
            <img src="https://img.icons8.com/plumpy/30/000000/x.png" class="close" />
          </label>


          <a href="#" class="logo-main">
            <img src={cleanworldlog} />
          </a>
          <ul class="nav__menu">

            <li class="active">    <NavLink to="/" className="nav__links ">{t('menu_bar_Home')}</NavLink></li>
            <li><NavLink to="/about" className="nav__links ">{t('menu_bar_About')}</NavLink></li>
            <li><NavLink to="/features" className="nav__links ">{t('menu_bar_Features')}</NavLink></li>
            <li><NavLink to="/testimonials" className="nav__links ">{t('menu_bar_Testimonials')}</NavLink></li>
            <li><NavLink to="/contact-us" className="nav__links ">{t('menu_bar_Contact')}</NavLink></li>
            <li><NavLink to="https://client.ccms.me/" className="nav__links btn head-btn " target="_blank">{t('menu_bar_Login')}</NavLink></li>

            <li><div class="btn-group btn-group-toggle" data-toggle="buttons">
            {language.map(({ code, name, country_code }) => (
              <label class="btn btn-secondary" key={country_code}>
                <input type="radio" name="options" id="option2" autocomplete="off" onClick={() => i18next.changeLanguage(code)} /><span className={`flag-icon flag-icon-${country_code} mx-2`}></span> {name}
              </label>

            ))}

          </div></li>
          </ul>
        

        </nav>
      </div>
      {/* <nav class="nav">
    <input type="checkbox" id="nav__checkbox" class="nav__checkbox"/>
    <label for="nav__checkbox" class="nav__toggle">
      <img src="https://img.icons8.com/material-outlined/30/000000/menu--v1.png" class="hamburger" />
      <img src="https://img.icons8.com/plumpy/30/000000/x.png" class="close" />
    </label>
  

    <a href="#" class="logo-main">
      <img src={cleanworldlog} />
    </a>
    <ul class="nav__menu">
      <li  onClick={handleNavBarClick2} ><a href="/"  className={showbar ==true?"active":"active"}>Home</a></li>
      <li  className="active" onClick={handleNavBarClick2} ><a href="/about"  className={showbar ==true?"active":""}>About</a></li>
      <li className="active" ><a href="/features"  className={showbar ==true?"active":""}>Features</a></li>
      <li className="active" ><a href="/testimonials"  className={showbar ==true?"active":""}>Testimonials</a></li>
      <li className="active" ><a href="#" class="btn head-btn" className={showbar ==true?"active":""}>Contact</a></li>
    </ul>
  </nav> */}




    </>
  )

}
export default NavBarPage;