
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
const CustomerTestimonialPage = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [readMore, setReadMore] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const text = <><p class="about-p1" style={{ textAlign: "justify" }}>
    {t('testimonials_home_para2')} </p>

    <p class="about-p1" style={{ textAlign: "justify" }}>
      {t('testimonials_home_para3')}</p></>



  const linkName = readMore ? <span>Show less</span> : <span>Show more</span>

  return (<>
    <section class="main-section">
      <div class="container">
        <div class="title-wrapper">
          <h2 class="left h1-b"> {t('heading_customer')} <span>{t('menu_bar_Testimonials')}</span></h2>
        </div>
        <div class="row justify-content-center">


          <div class="col-lg-5 col-md-12">
            <div class="title-wrapper">

              <iframe class="video-one" height src="https://www.youtube.com/embed/BuG92IiMCPM?autoplay=1&amp;mute=1&amp;rel=0">
              </iframe>


            </div>
          </div>

          <div class="col-lg-7 col-md-12 md-pb">
            <div class="title-wrapper">
              <h5 class="left h1-b"> {t('client_name')}</h5>
              <h6 class="left h1-b">{t('client_position')}</h6>
              <div class="client-rating">
                <i class="fa fa-star" aria-hidden="true"></i>
                <i class="fa fa-star" aria-hidden="true"></i>
                <i class="fa fa-star" aria-hidden="true"></i>
                <i class="fa fa-star" aria-hidden="true"></i>
                <i class="fa fa-star" aria-hidden="true"></i>
              </div>
            </div>
            <p class="about-p1" style={{ textAlign: "justify" }}>
              {t('testimonials_home_para1')}</p>


          </div>
          <div class="col-lg-12 col-md-12 md-pb">
            {readMore && text}

          </div>

          <button class="test-btn" onClick={() => { setReadMore(!readMore) }}>{linkName}<i class="fa fa-arrow-right" aria-hidden="true"></i></button>

        </div>
      </div>
    </section>







  </>)
}
export default CustomerTestimonialPage;