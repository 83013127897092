
import NavBarPage from "../components/navbar";
import FooterPage from "../components/footer";
import "./index.css"
const CleanWorldPrivacyPolicy = () => {
    return (<>
        <NavBarPage />
        <section class="about  container cleanworld">
            <div class="row justify-content-center">
                <p class="about-p1">
                    <strong>
                        <h3 class="pb-3 about-h1">Privacy policy </h3>
                    </strong>
                </p>
            </div>

            <p class="about-p1">

                At Cleaning Companies Management System, we prioritize the privacy and security of our users. This Privacy Policy outlines how we collect, use, and handle audio, video, and image data in our Android app published on the Play Store. By using our app, you consent to the practices described in this policy.
            </p>
            <ol>
                <p class="about-p1"> 1. Information We Collect:</p>
                <li>Audio Data: Our app may collect and record audio data from your device’s microphone. This includes any audio input generated by the user while using our app.</li>
                <li>Video Data: Our app may access your device’s camera to collect and capture video data. This includes video recordings and images.</li>
                <li>Image Data: Our app may request access to your device’s image gallery or capture images directly using the camera.</li>
                <p class="about-p1">2.Use of Collected Information:</p>
                <li>Audio Data: The audio data we collect is solely used for the intended purpose of our app’s functionality. This may include voice recognition, audio recording, or any other audio-related features as described in the app’s functionality.</li>
                <li>Video Data: The video data we collect is used for the intended purpose of our app’s functionality. This may include video recording, live streaming, or any other video-related features as described in the app’s functionality.</li>
                <li>Image Data: The image data we collect is used for the intended purpose of our app’s functionality. This may include image recognition, editing, or any other image-related features as described in the app’s functionality.</li>
                <p class="about-p1">3.Data Transmission:</p>
                <li>Third-party Services: We may use third-party service providers to process and store audio, video, and image data collected by our app. These service providers are carefully selected, and we ensure that they comply with industry-standard security measures to safeguard your data.</li>
                <li>Data Security: We take reasonable measures to protect the audio, video, and image data collected by our app against unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure.</li>
                <p class="about-p1">4.Data Sharing:</p>
                <li>Legal Compliance: We may share audio, video, and image data if required by law, regulation, or legal process.</li>
                <li>Consent: We will not share your audio, video, and image data with third parties for marketing purposes without your explicit consent.</li>

                <p class="about-p1">5.User Controls:</p>

                <li>Permissions: Our app may request permissions to access your device’s microphone, camera, or image gallery. You can control these permissions through your device settings.</li>
                <li>Opt-out: If you no longer wish to use our app’s audio, video, or image-related features, you can revoke the necessary permissions or discontinue using the app.</li>
                <p class="about-p1">6.Children’s Privacy:</p>

                <li>Our app is not intended for use by children under the age of 13. We do not knowingly collect personal information from children. If we discover that a child has provided us with personal information, we will promptly delete it.</li>
                <p class="about-p1">7.Changes to Privacy Policy:</p>
                <li>We may update this Privacy Policy from time to time. Any changes will be reflected in the updated policy, and the revised date will be indicated. We encourage you to review this policy periodically for any updates.</li>
            </ol>


        </section >
        < FooterPage />
    </>)
}
export default CleanWorldPrivacyPolicy;